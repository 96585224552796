<template>
  <div v-if="isLoggedIn">
    <v-navigation-drawer v-model="isMenuOpen" app fixed>
      <router-link :to="{name: 'home'}">
        <img :src="require('@/assets/logo.png')" class="logo">
      </router-link>
      <v-divider class="mt-10"/>
      <TheMainMenu/>
    </v-navigation-drawer>
    <v-app-bar app color="primary" dark mfixed>
      <v-app-bar-nav-icon @click.stop="isMenuOpen = !isMenuOpen"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ appTitle || 'Beheer' }}</v-toolbar-title>
      <VSpacer/>
      <AppBarMenu/>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <router-view/>
      </v-container>
    </v-main>
    <v-footer app color="primary" inset>
      <span class="white--text">Created by Kings Code</span>
    </v-footer>
    <TheConfirmationDialog/>
    <TheSnackbarQueueHandler/>
  </div>
</template>

<script>
import AppBarMenu from '@/components/AppBarMenu.vue';
import TheMainMenu from '@/components/menu/TheMainMenu.vue';
import { mapActions, mapGetters } from 'vuex';
import { FETCH_PROFILE } from '@/store/modules/profile/actionTypes.js';
import eventBus from '@/eventBus.js';
import TheConfirmationDialog from '@/components/TheConfirmationDialog.vue';
import TheSnackbarQueueHandler from '@/components/TheSnackbarQueueHandler.vue';

export default {
  name: 'Default',
  components: {
    TheSnackbarQueueHandler,
    TheConfirmationDialog,
    AppBarMenu,
    TheMainMenu,
  },
  data() {
    return {
      isMenuOpen: true,
      appTitle: '',
    };
  },
  methods: {
    ...mapActions('profile', { fetchProfile: FETCH_PROFILE }),
  },
  computed: {
    ...mapGetters('authorisation', ['isLoggedIn']),
  },
  async created() {
    await this.fetchProfile();
    if (!this.isLoggedIn) await this.$router.push({ name: 'login' });
    eventBus.$on('appTitle', (appTitle) => {
      this.appTitle = appTitle;
    });
  },
};
</script>

<style lang="scss" scoped>

.logo {
  cursor:  pointer;
  display: block;
  margin:  30px auto;
  width:   50%;
}
</style>

<style lang="scss">
.v-dialog--fullscreen > .v-card.v-sheet.theme--light, .theme--light.v-application {
  background: rgb(248, 249, 250);
}
</style>
