<template>
  <v-menu bottom left offset-y transition="slide-y-transition">
    <v-list>
      <v-list-item @click="navigateToProfile">{{ $t('global.template.menu.profile') }}</v-list-item>
      <v-list-item @click="logout">{{ $t('global.template.menu.logout') }}</v-list-item>
    </v-list>

    <template #activator="{ on }">
      <v-btn class="mr-6" fab icon v-on="on">
        <v-avatar class="accent" size="48" title>
          <v-icon>$user</v-icon>
        </v-avatar>
      </v-btn>
    </template>
  </v-menu>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AppBarMenu',
  methods: {
    navigateToProfile() {
      this.$router.push({ name: 'profile' });
    },
    ...mapActions({ logout: 'authorisation/logout' }),
  },
};
</script>
