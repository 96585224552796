<script lang="js">
import { mapGetters } from 'vuex';
import Menu from './Menu.vue';

export default {
  name: 'TheMainMenu',
  extends: Menu,
  computed: {
    ...mapGetters('profile', ['isAdmin', 'hasFavoriteUrl']),
    items() {
      if (this.isAdmin) {
        return [
          {
            icon: '$dashboard',
            title: this.$tc('portal.title', 1),
            route: { name: 'home' },
          },
          {
            icon: '$favorite',
            disabled: !this.hasFavoriteUrl,
            title: this.$tc('portal.favorite', 1),
            route: { name: 'favorite' },
          },
          {
            icon: '$users',
            title: this.$tc('user.title', 2),
            route: { name: 'users' },
          },
          {
            icon: '$role',
            title: this.$tc('role.title', 2),
            route: { name: 'roles' },
          },
          {
            icon: '$cubes',
            title: this.$tc('portal.title', 2),
            route: { name: 'dashboards' },
          },
        ];
      }
      return [
        {
          icon: '$dashboard',
          title: this.$tc('dashboard.title', 1),
          route: { name: 'home' },
        },
        {
          icon: '$favorite',
          disabled: !this.hasFavoriteUrl,
          title: this.$tc('portal.favorite', 1),
          route: { name: 'favorite' },
        },
      ];
    },
  },
};
</script>
